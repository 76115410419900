import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Hammer from 'hammerjs/hammer'
import shuffle from 'lodash/shuffle'

Vue.use(Vuex)

const PROG_DATA_URL = '/prog-data.json';
const RANKING_LIST_LEN = 20;
const SHORT_PROGRAM_LEN = 12;
const UNLIMITED_RADIUS = 160;
const GEO_API_URL = 'https://dz0sdv0xb3y4s.cloudfront.net/v1';
const LEAD_API_URL = 'https://sj6fpqg1a6.execute-api.us-east-1.amazonaws.com/v1_0';
const PROGRAMS_PATH = '/programs/';
const RANKINGS_PATH = '/rankings/';

const featuredPositions = [0, 4, 14, 19];

const d = document;

//
// Distance between coordinates
//
function getDistanceFromLatLonInMiles(lat1,lon1,lat2,lon2) {
	return getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) / 1.61;
}

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1);
  var a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}
//
// End distance between coordinates
//


//
// Asynchronous AJAX call
//
function ajax(method, url, data, success, failure) {
	const req = new XMLHttpRequest();
	req.open(method, url, true);
	if (method == 'POST') {
		req.setRequestHeader('Content-Type', 'application/json');
	}
	req.onload = function() {
		const http_success = this.status >= 200 && this.status < 400;
		if (http_success) {
			if (typeof success == 'function') {
				success(this.response);
			}
		} else {
			if (typeof failure == 'function') {
				failure();
			}
		}
	}
	req.onerror = function() {
		if (typeof failure == 'function') {
			failure();
		}
	}
	req.send(data);
}
//
// End - Asynchronous AJAX call
//


//
// ProgramList
//
ProgramList.prototype.STATE_INITIAL = 0;
ProgramList.prototype.STATE_LOADING = 1;
ProgramList.prototype.STATE_LOADED  = 2;
ProgramList.prototype.STATE_ERROR   = 3;

function ProgramList(programs) {
	this.programs = programs;
	this.state = this.programs ? this.STATE_LOADED : this.STATE_INITIAL;
}

//ProgramList.prototype.load = function (url) {
//	url = url || PROG_DATA_URL;
//	this.state = this.STATE_LOADING;
//
//	const self = this;
//
//	return new Promise(function(resolve, reject) {
//		ajax('GET', url, null,
//			function (data) {
//				self.programs = JSON.parse(data);
//				self.state = self.STATE_LOADED;
//				resolve(self);
//			},
//			function () {
//				console.log("Can't load programs");
//				reject();
//			}
//		);
//	});
//}

ProgramList.prototype.filter = function (criteria) {
	let fl = null;
	if (criteria && (fl = criteria.length) > 0) {
		const a = [];
		this.programs.forEach(function (p) {
			let is_match = true;
			for (let j = 0; j < fl && is_match; j++) {
				// Each criterion is an array of three elements:
				//   0 - field name
				//   1 - comparision operator
				//   2 - value
				let c = criteria[j];
				switch (c[1]) {
					case '==':
						is_match = p[c[0]] == c[2];
						break;
					case '!=':
						is_match = p[c[0]] != c[2];
						break;
					case '<=':
						is_match = p[c[0]] <= c[2];
						break;
					case '>=':
						is_match = p[c[0]] >= c[2];
						break;
					case '>':
						is_match = p[c[0]] > c[2];
						break;
					case 'in':
						// Third element (c[2]) is an array of possible values
						is_match = false;
						for (let k = 0; k < c[2].length; k++) {
							if (is_match = p[c[0]] == c[2][k]) {
								break;
							}
						}
						break;
					default:
						is_match = false;
				}
			}

			if (is_match) {
				a.push(p);
			}
		});

		return new ProgramList(a);

	} else {
		return new ProgramList(this.programs.slice());
	}
}

ProgramList.prototype.sortTop = function () {
	this.programs.sort(function(a, b) {
		var ret = b.top_score - a.top_score;
		if (ret === 0) {
			return a.instName < b.instName ? -1 : 1;
		} else {
			return ret
		}
	});
}

ProgramList.prototype.sortValue = function () {
	this.programs.sort(function(a, b) {
		var ret = b.value_score - a.value_score;
		if (ret === 0) {
			return a.instName < b.instName ? -1 : 1;
		} else {
			return ret
		}
	});
}

ProgramList.prototype.sortSchoolName = function () {
	this.programs.sort(function(a, b) {
		return a.instName < b.instName ? -1 : 1;
	});
}

ProgramList.prototype.sortDistance = function () {
	this.programs.sort(function(a, b) {
		return a.distance - b.distance;
	});
}

ProgramList.prototype.sort = function (f) {
	f.call(this);
	return this;
}

ProgramList.prototype.groupBySchool = function (maxResults) {
	let i = 0;
	let schools = [];
	let prevSchool = null;
	let prevCity = null;
	let prevState = null;
	let schoolsLen = 0;
	let programsLen = this.programs.length;
	let s = null;
	let l = null;
	let p = null;

	while (schoolsLen < maxResults && i < programsLen) {
		p = this.programs[i];

		if (p.instName !== prevSchool) {
			s = {
				instName:     p.instName,
				locations:    [],
				gpa:          10,
				tuition:      null,
				financialAid: null,
				programs:     []
			}
			schoolsLen = schools.push(s);
		}

		if (p.instName !== prevSchool ||
			  p.city !== prevCity || p.state !== prevState) {
			l = {
				city:     p.city,
				state:    p.state,
				programs: []
			};
			s.locations.push(l);
		}

		s.programs.push(p);
		l.programs.push(p);

		s.gpa = this.minGPA(s.gpa, p.gpa);
		s.tuition = this.minTuition(s.tuition, p.tuition);
		if (s.financialAid != 'Y' && p.financialAid == 'Y') {
			s.financialAid = 'Y';
		}

		i++;
		prevSchool = p.instName;
		prevCity = p.city;
		prevState = p.state;
	}

	return schools;
}

ProgramList.prototype.minGPA = function (a, b) {
	if (parseFloat(b) < parseFloat(a)) {
		return b;
	} else {
		return a;
	}
}

ProgramList.prototype.minTuition = function (a, b) {
	let an = 999999;
	let bn = 999999;

	try { an = parseInt(a.match(/[\d,]+/)[0].replace(',', '')) } catch(e) {}
	try { bn = parseInt(b.match(/[\d,]+/)[0].replace(',', '')) } catch(e) {}

	if (an === 999999 && bn === 999999) {
		return null;
	}

	if (bn < an) {
		return b;
	} else {
		return a;
	}
}

ProgramList.prototype.getPrograms = function (l) {
	return this.programs.slice(0, l || this.programs.length);
}

// TODO: This should be moved into Vuex store
ProgramList.prototype.calcDistances = function (lat, lng) {
	if (!lat || !lng) return;

	const pl = this.programs.length;
	for (let i = 0, count = 0; i < pl; i++) {
		let p = this.programs[i];
		if (p.location) {
			Vue.set(p, 'distance',
			        getDistanceFromLatLonInMiles(
			          lat, lng, p.location.lat, p.location.lng));
		}
	}
}

ProgramList.prototype.positionFeatured = function() {
	// Get featured programs
	let featuredPrograms = this.programs.filter(program => program.featured)
	let shuffledFeatured;
	// If already shuffled use existing
	if (store.state.featuredPrograms !== null) {
		shuffledFeatured = store.state.featuredPrograms
	} else {
		// Shuffle array of featured programs randomly
		shuffledFeatured = shuffle(featuredPrograms).slice(0, featuredPositions.length);
		store.commit('setFeaturedPrograms', shuffledFeatured);
	}
	// Get all non featured programs
	let programs =
		this.programs.filter(
			program => !shuffledFeatured.find(p => p.id === program.id)
		)
	// Merge featured and non featured programs together
	this.programs = [...programs]
	if (featuredPrograms.length > 1) {
		for (let i = 0; i < featuredPositions.length; i++) {
			// at 0, 4, 14, 19 replace with featured item and move item to end of array
			this.programs.splice(featuredPositions[i], 0, shuffledFeatured[i])
		}
	}
	return this;
}

ProgramList.prototype.getSimilarPrograms = function(programs) {
	if (!programs || programs.length == 0) {
		return null;
	}
	const progId = programs[0].id;
	if (programs[0].online == 'Y') {
		return this.programs.filter(p => p.online == 'Y' && p.id != progId).sort(p => 0.5 - Math.random()).slice(0, 3)
	}
	const region = programs[0].region;
	const cats = programs[0].categories;
	// Sort same region first, then randomize
	const randProgs = this.programs.slice().sort((a, b) => {
		if (   !region
		    || a.region == region && b.region == region
		    || a.region != region && b.region != region) {
			return 0.5 - Math.random();
		}
		if (a.region == region && b.region != region) { return -1; }
		if (a.region != region && b.region == region) { return 1; }
	});
	const simProgs = [];
	let simCount = 0;
	for (let i = 0; i < randProgs.length && simCount < 3; i++) {
		if (   randProgs[i].id != progId
		    && randProgs[i].categories.filter(c => cats.includes(c)).length > 0) {
			simProgs.push(randProgs[i]);
			simCount++;
		}
	}
	if (simCount < 3) {
		for (let i = 0; i < randProgs.length && simCount < 3; i++) {
			if (   randProgs[i].id != progId
			    && randProgs[i].region == region
			    && !simProgs.includes(randProgs[i])) {
				simProgs.push(randProgs[i]);
				simCount++;
			}
		}
	}
	return simProgs;
}

//
// End ProgramList
//


//
// Vuex application state
//

const store = new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',

	plugins: [createPersistedState()],

	state: {
		dropdownState: false,
		programs: null,
		featuredPrograms: null,
		similarPrograms: null,
		rank_type: 'best-degrees',
		gpa: 2,
		full_part: '',
		day_evening: '',
		program_type: '',
		is_online: '',
		program_length: '',
		zip: '',
		radius: UNLIMITED_RADIUS,
		zip_location: { lat: null, lng: null },

		rfi_step: 0,
		rfi_first: '',
		rfi_last: '',
		rfi_email: '',
		rfi_phone: '',
		rfi_edu_level: '',
		rfi_gpa: null,
		rfi_intended_start: '',

		lead_posted: false,
		leadMessageId: '',

		pendingProgramRequests: [],
	},

	getters: {
		pl: function(state) {
			return state.programs && new ProgramList(state.programs);
		},

		filtered_programs: function(state, getters) {
			if (getters.pl) {

				let f = [];

				if (state.gpa > 2) {
					f.push(['gpa', '>=', state.gpa]);
				}

				if (state.day_evening != '') {
					f.push(['day_evening', 'in', [state.day_evening, 'any']]);
				}

				if (state.full_part != '') {
					f.push(['full_part', 'in', [state.full_part, 'any']]);
				}

				if (state.program_type != '') {
					f.push(['degreeGranting', 'in', [state.program_type, 'any']]);
				}

				switch (state.is_online) {
					// This assumes that only 'Y' is present in the file
					case 'Y':
						f.push(['online', '==', 'Y']);
						break;
					case 'N':
						f.push(['online', '==', undefined]);
						break;
				}

				switch (state.program_length) {
					case 'short':
						f.push(['length', '<=', SHORT_PROGRAM_LEN]);
						break;
					case 'long':
						f.push(['length', '>', SHORT_PROGRAM_LEN]);
						break;
				}

				if (state.radius < UNLIMITED_RADIUS && state.zip) {
					f.push(['distance', '<=', state.radius]);
				}

				const filteredPL = getters.pl.filter(f);
				if (state.zip) {
					filteredPL.sortDistance();
				} else {
					filteredPL.sortSchoolName();
				}
				filteredPL.positionFeatured();

				return filteredPL.programs;

			} else {
				return [];
			}
		},

		featured_programs: function(state, getters) {
			// If already shuffled use existing
			if (state.featuredPrograms !== null) {
				return state.featuredPrograms;
			} else {
				// Shuffle array of featured programs randomly
				let programs = state.programs;
				if (!programs) return [];
				let programsArray = programs.filter(program => program.featured);
				const shuffledFeatured = shuffle(programsArray).slice(0, featuredPositions.length);
				store.commit('setFeaturedPrograms', shuffledFeatured);
				return shuffledFeatured;
			}
		}
	},

	mutations: {
		setPrograms:            function(state, v) { state.programs = v; },
		setFeaturedPrograms:    function(state, v) { state.featuredPrograms = v; },
		setSimilarPrograms:     function(state, v) { state.similarPrograms = v; },
		setProgramRequested:    function(state, program) {
			Vue.set(program, 'requested', true);
		},
		setProgramRequesting:   function(state, program) {
			Vue.set(program, 'requesting', true);
		},
		unsetProgramRequesting: function(state, program) {
			Vue.set(program, 'requesting', false);
		},

		updateRankType:         function(state, v) { state.rank_type = v; },
		updateGPA:              function(state, v) { state.gpa = v; },
		updateFullPart:         function(state, v) { state.full_part = v; },
		updateDayEvening:       function(state, v) { state.day_evening = v; },
		updateProgramType:      function(state, v) { state.program_type = v; },
		updateIsOnline:         function(state, v) { state.is_online = v; },
		updateProgramLength:    function(state, v) { state.program_length = v; },
		updateZip:              function(state, v) { state.zip = v; },
		updateRadius:           function(state, v) { state.radius = v; },
		updateZipLocation:      function(state, v) {
			state.zip_location = v;
			(new ProgramList(state.programs)).calcDistances(state.zip_location.lat, state.zip_location.lng);
		},

		rfiNextStep: function (state) { state.rfi_step++; },

		updateRFIFirst:      function (state, v) { state.rfi_first = v; },
		updateRFILast:       function (state, v) { state.rfi_last = v; },
		updateRFIEmail:      function (state, v) { state.rfi_email = v; },
		updateRFIPhone:      function (state, v) { state.rfi_phone = v; },
		updateRFIEduLevel:   function (state, v) { state.rfi_edu_level = v; },
		updateRFIGPA:        function (state, v) { state.rfi_gpa = v; },
		updateIntendedStart: function (state, v) { state.rfi_intended_start = v; },

		leadPosted: function (state) { state.lead_posted = true; },
		setLeadMessageId: function (state, v) { state.leadMessageId = v; },

		addProgramRequest: function (state, program) {
			state.pendingProgramRequests.push(program);
		},
		clearPendingProgramRequests: function (state) {
			state.pendingProgramRequests = [];
		},
	},

	actions: {
		loadPrograms: function (context, url) {
			ajax('GET', url, null,
				function (data) {
					context.commit('setPrograms', JSON.parse(data));
				},
				function () {
					console.log("Can't load programs");
				}
			);
		},

		requestProgram: function (context, program) {
			if (!program) {
				console.error("program is '" + program + "' in requestProgram");
				return;
			}

			context.commit('setProgramRequesting', program);
			ajax('POST', LEAD_API_URL + '/lead/program',
			     '{' +
			        '"leadMessageId": "' + context.state.leadMessageId + '",' +
			        '"school": "' + program.instName + '",' +
			        '"program": "' + program.name + '",' +
			        '"featured": ' + !!program.featured + ',' +
			        '"programSrcId": ' + program.id + '' +
			     '}',
			     function (data) {
			       const dataObj = JSON.parse(data);
			       const messageId = dataObj['messageId'];
			       if (messageId && messageId.trim() !== '') {
			         context.commit('setProgramRequested', program);
			         context.commit('unsetProgramRequesting', program);
			       }
			     });
		},

		queueProgramRequest: function (context, program) {
			context.commit('addProgramRequest', program);
			if (context.state.lead_posted) {
				context.dispatch('processProgramRequestQueue');
			}
		},

		processProgramRequestQueue: function (context) {
			if (context.state.lead_posted) {
				Array.prototype.forEach.call(
					context.state.pendingProgramRequests,
					function (program) {
						context.dispatch('requestProgram', program);
					});
				context.dispatch('showSimilar', context.state.pendingProgramRequests);
				context.commit('clearPendingProgramRequests');
			}
		},

		showSimilar: function (context, programs) {
			d.getElementById('similar-overlay').style.display = 'none';
			d.getElementById('similar').style.display = 'none';
			d.body.style.overflow = 'initial';
			if (   context.state.similarPrograms && context.state.similarPrograms.length
			    || window.innerWidth < 900) {
				return;
			}
			context.commit("setSimilarPrograms", (new ProgramList(context.state.programs)).getSimilarPrograms(programs));
			setTimeout(function () {
				if (store.state.similarPrograms && store.state.similarPrograms.length) {
					d.getElementById('similar-overlay').style.display = 'block';
					d.getElementById('similar').style.display = 'block';
				}
			}, 1);
		}
	}
});

const rt = getUrlParameter('rt');


const classList = document.querySelector('body').classList;

if (!classList.contains('rankings-main')) {
	let filterToUse = '';

	if (classList.contains('best-online')) filterToUse = 'best-online';
	if (classList.contains('best-post-bacc-certificates')) filterToUse = 'best-certs';
	if (classList.contains('best-post-bacc-degrees')) filterToUse = 'best-degrees';
	if (classList.contains('best-value-online')) filterToUse = 'value-online';
	if (classList.contains('best-value-post-bacc-certificates')) filterToUse = 'value-certs';
	if (classList.contains('best-value-post-bacc-degrees')) filterToUse = 'value-degrees';

	store.commit('updateRankType', filterToUse);
}


if (rt) {
	store.commit('updateRankType', rt);
}

if (!store.state.programs) {
	store.dispatch('loadPrograms', PROG_DATA_URL);
}

//
// End Vuex application state
//


const program_button_opt = {
	name: 'program-button',
	props: ['program', 'programs'],
	template: '<button v-on:click="request" ' +
	                  'v-bind:class="{ requested: requested, requesting: requesting }">' +
	            '{{ buttonText }}' +
	          '</button>',

	computed: {
		_programs: function () {
			if (this.programs) {
				return this.programs;
			}

			if (typeof this.program === 'number' || typeof this.program === 'string') {
				// program parameter is an id
				const ps = this.$store.state.programs;
				let ret = null;
				if (ps) {
					const l = ps.length;
					for (let i = 0; i < l && !ret; i++) {
						if (ps[i].id == this.program) {
							ret = ps[i];
						}
					}
				}
				return [ret];
			} else {
				// program parameter is a program object
				return [this.program];
			}
		},

		requested: function () {
			return !this.requesting &&
			       this._programs &&
			       this._programs.length > 0 &&
			       this._programs[this._programs.length - 1] &&
			       this._programs[this._programs.length - 1].requested;
		},

		requesting: function () {
			return this._programs &&
			       this._programs.length > 0 &&
			       this._programs[0] &&
			       this._programs[0].requesting;
		},

		buttonText: function () {
			return this.requested ? 'Requested' : 'Request Info';
		}
	},

	methods: {
		request: function() {
			this.$emit('requested', this._programs);

			const len = this._programs.length;
			for (let i = 0; i < len; i++) {
				this.$store.dispatch('queueProgramRequest', this._programs[i]);
			}

			if (this.$store.state.rfi_step == 0) {
				this.$store.commit('rfiNextStep');
			}

			if (!this.$store.state.lead_posted) {
				d.getElementById('rfi').style.display = 'flex';
				d.body.style.overflow = 'hidden';
			}
		}
	}
};


//
// Rankings list
//
if (d.getElementById('rankings-list')) {
	new Vue({
		el: '#rankings-list',

		// Component specific state
		data() {
			return {
				dropdownState: false,
				body: document.querySelector('body') // Non reactive element outside scope of this application.
			}
		},

		components: { 'program-button': program_button_opt },

		store: store,

		methods: {
			resetDropdownState() {
				this.body.classList.remove('noOverflow')
				this.dropdownState = false;
			},
			toggleDropdownState() {
				this.body.classList.toggle('noOverflow')
				this.dropdownState = !this.dropdownState;
			}
		},

		computed: {
			rank_type: {
				get: function () {
					return this.$store.state.rank_type;
				},
				set: function (v) {
					this.dropdownState = false;
					this.$store.commit('updateRankType', v);
				}
			},

			dropdown_state() {
				return this.$store.state.dropdownState
			},

			schools: function () {
				return this.pl && this.pl.filter(this.filter)
						                     .sort(this.sort)
				                         .groupBySchool(RANKING_LIST_LEN);
			},

			filter: function () {
				if (this.prog_type == 'online') {
					return this.prog_type == 'online'
				         ? [['online', '==', 'Y']]
				         : [['online', '!=', 'Y']]
				}
				return this.prog_type == 'degrees'
				         ? [['degreeGranting', '==', 'Y'], ['online', '!=', 'Y']]
				         : [['degreeGranting', '!=', 'Y'], ['online', '!=', 'Y']]
			},

			sort: function () {
				return this.sort_type == 'best'
				       ? ProgramList.prototype.sortTop
				       : ProgramList.prototype.sortValue;
			},

			pl: function () {
				return this.$store.state.programs &&
				       new ProgramList(this.$store.state.programs);
			},

			prog_type: function () {
				return this.rank_type.split('-')[1];
			},

			sort_type: function () {
				return this.rank_type.split('-')[0];
			},

			is_loaded: function () {
				//return this.state == ProgramList.prototype.STATE_LOADED;
				return Boolean(this.schools);
			}
		},
	});
}
//
// End rankings list
//


//
// Program filter
//
const filter_toggle = d.getElementById('filter-toggle'),
      filter_form   = d.getElementById('filter-form');

if ( filter_toggle && filter_form ) {

	// Range handler
	const present_ranges = [];

	const range_handler = function(id, units, max_label) {
		if (typeof units !== 'undefined') {
			units = ' ' + units;
		} else {
			units = '';
		}

		const el = d.getElementById(id);
		const val_el = d.getElementById(id + '-val');
		if (el && val_el) {
			present_ranges.push([el, val_el]);
			let max_val = null;
			if (max_label) {
				max_val = +el.getAttribute('max');
			}
			el.addEventListener('change', function() {
				if (max_val === null || el.value < max_val) {
					val_el.innerHTML = el.value + units;
				} else {
					val_el.innerHTML = max_label;
				}
			});
		}
	}

	range_handler('gpa');
	range_handler('radius', 'miles', '∞');
}
//
// End program filter
//


//
// Program filter component
//

const program_filter_opt = {
	name: 'program-filter',
	template: '#program-filter-template',

	props: { 'showCount': { type: Boolean, default: true } },

	data: function () {
		return { showProgramTypeTooltip: false };
	},

	computed: {
		pl: function () {
			return this.$store.state.programs &&
			       new ProgramList(this.$store.state.programs);
		},

		gpa: {
			get: function () { return this.$store.state.gpa; },
			set: function (v) { this.$store.commit('updateGPA', v); }
		},

		full_part: {
			get: function () { return this.$store.state.full_part; },
			set: function (v) { this.$store.commit('updateFullPart', v); }
		},

		day_evening: {
			get: function () { return this.$store.state.day_evening; },
			set: function (v) { this.$store.commit('updateDayEvening', v); }
		},

		program_type: {
			get: function () { return this.$store.state.program_type; },
			set: function (v) { this.$store.commit('updateProgramType', v); }
		},

		is_online: {
			get: function () { return this.$store.state.is_online; },
			set: function (v) { this.$store.commit('updateIsOnline', v); }
		},

		program_length: {
			get: function () { return this.$store.state.program_length; },
			set: function (v) { this.$store.commit('updateProgramLength', v); }
		},

		zip: {
			get: function () { return this.$store.state.zip; },
			set: function (v) { this.$store.commit('updateZip', v); }
		},

		radius: {
			get: function () { return this.$store.state.radius; },
			set: function (v) { this.$store.commit('updateRadius', v); }
		},

		zip_location: {
			get: function () { return this.$store.state.zip_location; },
			set: function (v) { this.$store.commit('updateZipLocation', v); }
		},

		programs: function () {
			return this.$store.getters.filtered_programs;
		},

		prog_count: function () {
			return this.programs ? this.programs.length : 0;
		},

		radius_val_label: function () {
			if (this.radius < UNLIMITED_RADIUS) {
				return this.radius + ' miles';
			} else {
				return '∞';
			}
		}
	},

	watch: {
		zip: function () {
			if (this.zip_el.validity.valid && this.zip.length == 5) {
				this.update_zip_location();
			}
		}
	},

	methods: {
		reset_filter: function (e) {
			this.gpa = 2;
			this.full_part = '';
			this.day_evening = '';
			this.program_type = '';
			this.is_online = '',
			this.program_length = '';
			this.zip = '';
			this.radius = UNLIMITED_RADIUS;
			e && e.preventDefault();
		},

		update_zip_location: function () {
			const zip = this.zip;
			const self = this;

			this.zip_el.classList.remove('error');

			ajax(
				'GET',
				GEO_API_URL +
					'?components=country:US%7Cpostal_code:' + encodeURIComponent(zip),
				null,
				function (data) {
					let r, g, l, t;
					if ( (r = JSON.parse(data).results) && r.length > 0 &&
					     (t = r[0].types) && t.indexOf('postal_code') !== -1 &&
					     (g = r[0].geometry) &&
					     (l = g.location) ) {
						self.zip_location = { lng: l.lng, lat: l.lat };
					} else {
						console.log("Can't geo-locate zip '" + zip + "'");
						self.zip_el.classList.add('error');
					}
				},
				function () {
					console.log("Can't geo-locate zip '" + zip + "'");
					self.zip_el.classList.add('error');
				}
			);
		},

		zip_blur: function (e) {
			e.target.minLength = 5;
		}
	},

	mounted: function() {
		this.zip_el = d.getElementById('zip');
	}
};


//
// Program list
//
if (d.getElementById('program-list')) {
	new Vue({
		el: '#filtered-programs',

		store: store,

		data: {
			state: null,
			filter_auto_height: -1,
			scrolled_to_program: false
		},

		components: {
			'program-filter': program_filter_opt,
			'program-button': program_button_opt,
		},

		computed: {
			programs: function () { return this.$store.getters.filtered_programs; },

			is_loading: function () {
				//return this.state == ProgramList.prototype.STATE_LOADING;
				return !this.programs;
			},

			is_error: function () {
				//return this.state == ProgramList.prototype.STATE_ERROR;
				return false;
			},

			is_loaded: function () {
				//return this.state == ProgramList.prototype.STATE_LOADED;
				return Boolean(this.programs);
			}
		},

		methods: {
			toggle_filter: function () {
				// If we get here before window.onload
				if (this.filter_auto_height === -1) {
					this.filter_auto_height = this.getAutoHeight();
				}

				this.filter_toggle.classList.toggle('on');

				if (this.filter_toggle.classList.contains('on')) {
					this.filter_form.style.maxHeight =
						'calc(' + this.filter_auto_height + 'px + 4em)';
				} else {
					this.filter_form.style.maxHeight = '0';
				}
			},

			getAutoHeight: function() {
				let wanted_height = this.filter_form.offsetHeight;

				this.filter_form.style.maxHeight  = '0';
				this.filter_form.style.position   = 'static';
				this.filter_form.style.visibility = 'visible';

				return wanted_height;
			},

			show_rfi: function () {
				d.getElementById('rfi').style.display = 'flex';
				d.body.style.overflow = 'hidden';
			},

			scroll_to_program : function () {
				if (this.scrolled_to_program) { return; }
				this.scrolled_to_program = true;
				var program = d.getElementById(window.location.hash.substring(1));
				if (program === null){ return; }
				window.scrollTo(0, program.offsetTop - 10);
			} 
		},

		mounted: function() {
			this.$el.style.visibility = 'visible';
			this.filter_form = d.getElementById('filter-form');
			this.filter_toggle = d.getElementById('filter-toggle');
			this.filter_auto_height = this.getAutoHeight();
		},

		updated: function () {
			this.$nextTick(() => {
				this.scroll_to_program();
			});
		}
	});
}
//
// End program list
//

//
// Form
//
const rfi = d.getElementById('rfi');
if (rfi) {
	function Lead(first, last, email, phone, zip, edu_level, gpa, intended_start) {
		this.first = first;
		this.last = last;
		this.email = email;
		this.phone = phone;
		this.zip = zip;
		this.edu_level = edu_level;
		this.gpa = gpa;
		this.intended_start = intended_start;
	}

	Lead.prototype.post = function () {
		const self = this;
		return new Promise(function(resolve, reject) {
			let source = getCookie('utm_source');
			if (source) source = '"utmSource": "' + source + '",';
			let medium = getCookie('utm_medium');
			if (medium) medium = '"utmMedium": "' + medium + '",';
			let campaign = getCookie('utm_campaign');
			if (campaign) campaign = '"utmCampaign": "' + campaign + '",';
			let term = getCookie('utm_term');
			if (term) term = '"utmTerm": "' + term + '",';
			let content = getCookie('utm_content');
			if (content) content = '"utmContent": "' + content + '",';
			let gclid = getCookie('gclid');
			if (gclid) gclid = '"gclid": "' + gclid + '",';

			ajax('POST', LEAD_API_URL + '/lead',
			     '{' +
			        source + medium + campaign + term + content + gclid +
			        '"firstName": "' + self.first + '",' +
			        '"lastName": "' + self.last + '",' +
			        '"email": "' + self.email + '",' +
			        '"phone": "' + self.phone + '",' +
			        '"zip": "' + self.zip + '",' +
			        '"eduLevel": "' + self.edu_level + '",' +
			        '"gpa": ' + parseFloat(self.gpa) + ',' +
				      '"intendedStart": "' + self.intended_start + '",' +
			        '"filterGPA": ' + store.state.gpa + ',' +
			        '"filterFullPart": "' + store.state.full_part + '",' +
			        '"filterDayEvening": "' + store.state.day_evening + '",' +
			        '"filterProgramType": "' + store.state.program_type + '",' +
							//'"filterIsOnline": "' + store.state.is_online + '",' +
			        '"filterProgramLength": "' + store.state.program_length + '",' +
			        '"filterRadius": ' + store.state.radius +
			     '}',
			     function (data) {
			       const dataObj = JSON.parse(data);
			       const leadMessageId = dataObj['messageId'];
			       if (leadMessageId && leadMessageId.trim() !== '') {
			      	 resolve(leadMessageId);
			       } else {
			      	 reject();
			       }
			     },
			     reject);
		});
	};

	Array.prototype.forEach.call(
		d.getElementsByClassName('show-rfi'),
		function (el) {
			el.addEventListener('click', function () {
				if (!store.state.lead_posted) {
					d.getElementById('rfi').style.display = 'flex';
					d.body.style.overflow = 'hidden';
				} else {
					if (window.location.pathname !== PROGRAMS_PATH) {
						window.location = PROGRAMS_PATH;
					}
				}
			});
		});

	const personal_info_opt = {
		template: '#personal-info-template',

		computed: {
			zip: {
				get: function () { return this.$store.state.zip; },
				set: function (v) { this.$store.commit('updateZip', v); }
			},

			first_name: {
				get: function () { return this.$store.state.rfi_first; },
				set: function (v) { this.$store.commit('updateRFIFirst', v); }
			},

			last_name: {
				get: function () { return this.$store.state.rfi_last; },
				set: function (v) { this.$store.commit('updateRFILast', v); }
			},

			email: {
				get: function () { return this.$store.state.rfi_email; },
				set: function (v) { this.$store.commit('updateRFIEmail', v); }
			},

			phone: {
				get: function () { return this.$store.state.rfi_phone; },
				set: function (v) { this.$store.commit('updateRFIPhone', v); }
			},

			edu_level: {
				get: function () { return this.$store.state.rfi_edu_level; },
				set: function (v) { this.$store.commit('updateRFIEduLevel', v); }
			},

			gpa: {
				get: function () { return this.$store.state.rfi_gpa; },
				set: function (v) { this.$store.commit('updateRFIGPA', v); }
			},

			intended_start: {
				get: function () { return this.$store.state.rfi_intended_start; },
				set: function (v) { this.$store.commit('updateIntendedStart', v); }
			},
		},

		methods: {
			zip_blur: function (e) {
				e.target.minLength = 5;
			}
		}
	};

	new Vue({
		el: rfi,

		store: store,

		components: {
			'program-filter': program_filter_opt,
			'personal-info': personal_info_opt
		},

		data: {
			steps: [
			         {
								 button_text: 'Match!',
								 comp: 'program-filter',
								 comp_props: { showCount: false },
								 description_text: 'Tell us what you are looking for in a post-bacc'
							 },
			         { button_text: 'Submit',
								 comp: 'personal-info',
								 comp_props: null,
								 description_text: 'Tell us who you are'
							 }
			       ]
		},

		computed: {
			current_step: function () {
				if (window.location.pathname == PROGRAMS_PATH &&
				    this.$store.state.rfi_step == 0) {
					this.$store.commit('rfiNextStep');
				}
				return this.$store.state.rfi_step;
			},

			current_step_comp: function () {
				return this.steps[this.current_step].comp;
			},

			current_step_comp_props: function () {
				return this.steps[this.current_step].comp_props;
			},

			button_text: function () {
				return this.steps[this.current_step].button_text;
			},

			description_text: function () {
				return this.steps[this.current_step].description_text;
			}
		},

		methods: {
			hide: function () {
				this.$el.style.display = 'none';
				d.body.style.overflow = 'initial';
			},

			button_click: function () {
				if (this.current_step < this.steps.length - 1) {
					this.$store.commit('rfiNextStep');
				} else {
					// Validation
					const form = this.$el.getElementsByTagName('form')[0];
					if (!form.reportValidity()) {
						form.classList.add('validate');
						return;
					}

					const self = this;
					const lead = new Lead(this.$store.state.rfi_first,
					                      this.$store.state.rfi_last,
					                      this.$store.state.rfi_email,
					                      this.$store.state.rfi_phone,
					                      this.$store.state.zip,
					                      this.$store.state.rfi_edu_level,
					                      this.$store.state.rfi_gpa,
					                      this.$store.state.rfi_intended_start);
					lead.post()
					.then(function (leadMessageId) {
						self.$store.commit('setLeadMessageId', leadMessageId);
						self.$store.commit('leadPosted');

						const programsQueued =
							self.$store.state.pendingProgramRequests.length > 0;
						self.$store.dispatch('processProgramRequestQueue');

						if (typeof gtag === 'function') {
							gtag('event', 'lead_post');
						}

						if (typeof datLayer === 'function') {
							dataLayer.push({'event': 'lead_post'});
						}

						if (window.location.pathname == PROGRAMS_PATH || programsQueued) {
							self.hide();
						} else {
							window.location = PROGRAMS_PATH;
						}
					});
				}
			}
		},

		created: function() {
			//Hide the form on ESC
			const self = this;
			d.addEventListener('keyup', function (e) {
				if(e.which == 27) self.hide();
			});
		}
	});
}

//
// Featured resources slider
//
const slider_controls = d.getElementsByClassName('slider-controls');
const sc_len = slider_controls.length;

if ( sc_len > 0 ) {
	let sc_update_width = function(el) {
		const w = el.parentNode.getElementsByClassName('slider-controls')[0].offsetWidth;
		el.style.marginLeft =
			(parseInt(el.style.marginLeft) || 0)
			/ el.children[0].offsetWidth
			* w
			+ 'px';
		for (let i = 0; i < el.children.length; i++) {
			el.children[i].style.width = w + 'px';
		}
	}

	let sc_update_dots = function(sc, active_slide) {
		setTimeout(function () {
			sc.getElementsByClassName('active')[0].classList.remove( 'active' );
			sc.getElementsByClassName('dot')[active_slide].classList.add( 'active' );
		}, 100 );
	}

	let sc_slide = function(el, offset) {
		let slide_width = el.children[0].offsetWidth;

		let left_margin = (parseInt(el.style.marginLeft) || 0)
		                  - offset * slide_width;

		let slides_num = el.children.length;

		if (left_margin > 0 || left_margin < -(slides_num - 1) * slide_width) {
			return;
		}

		el.style.marginLeft = left_margin + 'px';

		let sc = el.parentNode.getElementsByClassName('slider-controls')[0];
		if (left_margin === 0) {
			sc.getElementsByClassName('prev')[0].classList.add('disabled');
		} else {
			sc.getElementsByClassName('prev')[0].classList.remove('disabled');
			if (left_margin === -(slides_num - 1) * slide_width) {
				sc.getElementsByClassName('next')[0].classList.add('disabled');
			} else {
				sc.getElementsByClassName('next')[0].classList.remove('disabled');
			}
		}

		sc_update_dots(sc, -left_margin / slide_width);
	}

	for ( let i = 0; i < sc_len; i++ ) {
		// Set fixed widths
		sc_update_width(slider_controls[i].parentNode.children[0]);
		window.addEventListener('resize', function () {
			sc_update_width(slider_controls[i].parentNode.children[0]);
		});

		// Swipe navigation
		let hm = new Hammer(slider_controls[i].parentNode.children[0]);
		hm.on("swipeleft",  function(e) { sc_slide(hm.element, 1);  });
		hm.on("swiperight", function(e) { sc_slide(hm.element, -1); });

		// Prev/next buttons
		let next = slider_controls[i].getElementsByClassName('next')[0];
		next.addEventListener( 'click', function ( e ) {
			if ( e.target.classList.contains( 'disabled' ) ) { return; }
			let slide_target = e.target.parentNode.parentNode.children[0];
			sc_slide(slide_target, 1);
		});

		let prev = slider_controls[i].getElementsByClassName('prev')[0];
		prev.addEventListener( 'click', function ( e ) {
			if ( e.target.classList.contains( 'disabled' ) ) { return; }
			let slide_target = e.target.parentNode.parentNode.children[0];
			sc_slide(slide_target, -1);
		});
	}
}
//
// End featured resources slider
//

const programCards = document.getElementsByClassName('program-card');
for(const programCard of programCards) {
	const dataId = programCard.getAttribute('data-program')
	new Vue({
		el: programCard,
		store: store,
		data: {
			program: dataId
		},
		components: { 'program-button': program_button_opt },
		computed: {
			_program: function () {
				const ps = this.$store.state.programs;
				if (ps) {
					const prog = ps.filter(p => p.id == this.program)
					if (prog.length) {
						return prog[0]
					}
				}
				return null;
			},
			img: function () {
				return this.instName ? `/inst-logos/${this.instName.trim().replace('/', ' ')}.png` : ''
			},
			instName: function () {
				return this._program ? this._program.instName : ''
			},
			instNameLogo: function () {
				return this.instName + ' logo';
			},
			name: function () {
				return this._program ? this._program.name : ''
			},
			city: function () {
				return this._program ? this._program.city : ''
			},
			state: function () {
				return this._program ? this._program.state : ''
			},
			gpa: function () {
				return this._program ? this._program.gpa : ''
			},
			online: function () {
				return (this._program && this._program.hasOwnProperty('online')) ? '<span class="isOnline">Online</span>' : ''
			},
			tuition: function () {
				return this._program ? (this._program.tuition ? this._program.tuition : 'N/A') : ''
			},
			financialAid: function () {
				return this._program ? (this._program.financialAid === 'Y' ? '<strong><span>Available<sup><a href="#fa-disclaimer">*</a></sup></span></strong>' : '<strong><span>N/A</span></strong>') : ''
			},
			comments: function () {
				return this._program ? this._program.comments : ''
			}
		}
	})
}

//
// Home featured programs
//
const fp_el = d.getElementById('featured-programs');
if (fp_el) {
	new Vue({
		el: fp_el,

		store: store,

		components: { 'program-button': program_button_opt },

		computed: {
			programs: function () { return this.$store.getters.featured_programs; },
		},

		methods: {
			onRequested: function (programs) {
				if (typeof gtag === 'function') {
					gtag('event', 'home_featured_request',
					     { 'event_label': programs[0].instName + ' | ' + programs[0].name });
				}
			}
		}
	});
}
//
// End Home featured programs
//

//
// Similar programs popup
//
const similar_el = d.getElementById('similar');
if (similar_el) {
	new Vue({
		el: similar_el,

		data: {
			overlay_el: d.getElementById('similar-overlay'),
		},

		store: store,

		components: { 'program-button': program_button_opt },

		computed: {
			programs: function () { return this.$store.state.similarPrograms; },
		},

		methods: {
			hide: function () {
				this.$el.style.display = 'none';
				this.overlay_el.style.display = 'none';
				d.body.style.overflow = 'initial';
			},
		},
	});
}
//
// End Similar programs popup
//

function getUrlParameter(name) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	var results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

//Parse URL parameters and store as cookies
var cookies = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
for(var i = 0; i < cookies.length; i++) {
	document.cookie = cookies[i] + ' ;path=/';
}

function getCookie(name) {
	if(document.cookie.length > 0) {
		var start = document.cookie.indexOf(name + "=");
		var end;
		if(start != -1) {
			start = start + name.length + 1;
			end = document.cookie.indexOf(";", start);
			if(end == -1) {
				end = document.cookie.length;
			}
			return decodeURIComponent(document.cookie.substring(start, end));
		}
	}
	return "";
}
